// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-cli-cnt-md": () => import("./../../../src/pages/cli/cnt.md" /* webpackChunkName: "component---src-pages-cli-cnt-md" */),
  "component---src-pages-cli-create-mdx": () => import("./../../../src/pages/cli/create.mdx" /* webpackChunkName: "component---src-pages-cli-create-mdx" */),
  "component---src-pages-cli-generator-mdx": () => import("./../../../src/pages/cli/generator.mdx" /* webpackChunkName: "component---src-pages-cli-generator-mdx" */),
  "component---src-pages-cli-schema-mdx": () => import("./../../../src/pages/cli/schema.mdx" /* webpackChunkName: "component---src-pages-cli-schema-mdx" */),
  "component---src-pages-generator-graphql-modules-md": () => import("./../../../src/pages/generator/graphql-modules.md" /* webpackChunkName: "component---src-pages-generator-graphql-modules-md" */),
  "component---src-pages-generator-index-mdx": () => import("./../../../src/pages/generator/index.mdx" /* webpackChunkName: "component---src-pages-generator-index-mdx" */),
  "component---src-pages-generator-nexus-md": () => import("./../../../src/pages/generator/nexus.md" /* webpackChunkName: "component---src-pages-generator-nexus-md" */),
  "component---src-pages-generator-sdl-md": () => import("./../../../src/pages/generator/sdl.md" /* webpackChunkName: "component---src-pages-generator-sdl-md" */),
  "component---src-pages-index-md": () => import("./../../../src/pages/index.md" /* webpackChunkName: "component---src-pages-index-md" */),
  "component---src-pages-plugins-delete-md": () => import("./../../../src/pages/plugins/delete.md" /* webpackChunkName: "component---src-pages-plugins-delete-md" */),
  "component---src-pages-plugins-sdl-inputs-md": () => import("./../../../src/pages/plugins/sdl-inputs.md" /* webpackChunkName: "component---src-pages-plugins-sdl-inputs-md" */),
  "component---src-pages-plugins-select-md": () => import("./../../../src/pages/plugins/select.md" /* webpackChunkName: "component---src-pages-plugins-select-md" */),
  "component---src-pages-prisma-admin-md": () => import("./../../../src/pages/prisma-admin.md" /* webpackChunkName: "component---src-pages-prisma-admin-md" */),
  "component---src-pages-ui-components-accordion-tsx": () => import("./../../../src/pages/ui/components/accordion.tsx" /* webpackChunkName: "component---src-pages-ui-components-accordion-tsx" */),
  "component---src-pages-ui-components-actions-tsx": () => import("./../../../src/pages/ui/components/actions.tsx" /* webpackChunkName: "component---src-pages-ui-components-actions-tsx" */),
  "component---src-pages-ui-components-alert-tsx": () => import("./../../../src/pages/ui/components/alert.tsx" /* webpackChunkName: "component---src-pages-ui-components-alert-tsx" */),
  "component---src-pages-ui-components-badge-tsx": () => import("./../../../src/pages/ui/components/badge.tsx" /* webpackChunkName: "component---src-pages-ui-components-badge-tsx" */),
  "component---src-pages-ui-components-button-tsx": () => import("./../../../src/pages/ui/components/button.tsx" /* webpackChunkName: "component---src-pages-ui-components-button-tsx" */),
  "component---src-pages-ui-components-card-tsx": () => import("./../../../src/pages/ui/components/card.tsx" /* webpackChunkName: "component---src-pages-ui-components-card-tsx" */),
  "component---src-pages-ui-components-chat-tsx": () => import("./../../../src/pages/ui/components/chat.tsx" /* webpackChunkName: "component---src-pages-ui-components-chat-tsx" */),
  "component---src-pages-ui-components-checkbox-tsx": () => import("./../../../src/pages/ui/components/checkbox.tsx" /* webpackChunkName: "component---src-pages-ui-components-checkbox-tsx" */),
  "component---src-pages-ui-components-context-menu-tsx": () => import("./../../../src/pages/ui/components/context-menu.tsx" /* webpackChunkName: "component---src-pages-ui-components-context-menu-tsx" */),
  "component---src-pages-ui-components-eva-icon-tsx": () => import("./../../../src/pages/ui/components/eva-icon.tsx" /* webpackChunkName: "component---src-pages-ui-components-eva-icon-tsx" */),
  "component---src-pages-ui-components-flip-card-tsx": () => import("./../../../src/pages/ui/components/flip-card.tsx" /* webpackChunkName: "component---src-pages-ui-components-flip-card-tsx" */),
  "component---src-pages-ui-components-grid-tsx": () => import("./../../../src/pages/ui/components/grid.tsx" /* webpackChunkName: "component---src-pages-ui-components-grid-tsx" */),
  "component---src-pages-ui-components-input-tsx": () => import("./../../../src/pages/ui/components/input.tsx" /* webpackChunkName: "component---src-pages-ui-components-input-tsx" */),
  "component---src-pages-ui-components-layout-tsx": () => import("./../../../src/pages/ui/components/layout.tsx" /* webpackChunkName: "component---src-pages-ui-components-layout-tsx" */),
  "component---src-pages-ui-components-list-tsx": () => import("./../../../src/pages/ui/components/list.tsx" /* webpackChunkName: "component---src-pages-ui-components-list-tsx" */),
  "component---src-pages-ui-components-menu-tsx": () => import("./../../../src/pages/ui/components/menu.tsx" /* webpackChunkName: "component---src-pages-ui-components-menu-tsx" */),
  "component---src-pages-ui-components-popover-tsx": () => import("./../../../src/pages/ui/components/popover.tsx" /* webpackChunkName: "component---src-pages-ui-components-popover-tsx" */),
  "component---src-pages-ui-components-progress-tsx": () => import("./../../../src/pages/ui/components/progress.tsx" /* webpackChunkName: "component---src-pages-ui-components-progress-tsx" */),
  "component---src-pages-ui-components-radio-tsx": () => import("./../../../src/pages/ui/components/radio.tsx" /* webpackChunkName: "component---src-pages-ui-components-radio-tsx" */),
  "component---src-pages-ui-components-reveal-card-tsx": () => import("./../../../src/pages/ui/components/reveal-card.tsx" /* webpackChunkName: "component---src-pages-ui-components-reveal-card-tsx" */),
  "component---src-pages-ui-components-search-tsx": () => import("./../../../src/pages/ui/components/search.tsx" /* webpackChunkName: "component---src-pages-ui-components-search-tsx" */),
  "component---src-pages-ui-components-select-tsx": () => import("./../../../src/pages/ui/components/select.tsx" /* webpackChunkName: "component---src-pages-ui-components-select-tsx" */),
  "component---src-pages-ui-components-sidebar-tsx": () => import("./../../../src/pages/ui/components/sidebar.tsx" /* webpackChunkName: "component---src-pages-ui-components-sidebar-tsx" */),
  "component---src-pages-ui-components-spinner-tsx": () => import("./../../../src/pages/ui/components/spinner.tsx" /* webpackChunkName: "component---src-pages-ui-components-spinner-tsx" */),
  "component---src-pages-ui-components-tabs-tsx": () => import("./../../../src/pages/ui/components/tabs.tsx" /* webpackChunkName: "component---src-pages-ui-components-tabs-tsx" */),
  "component---src-pages-ui-components-toastr-tsx": () => import("./../../../src/pages/ui/components/toastr.tsx" /* webpackChunkName: "component---src-pages-ui-components-toastr-tsx" */),
  "component---src-pages-ui-components-tooltip-tsx": () => import("./../../../src/pages/ui/components/tooltip.tsx" /* webpackChunkName: "component---src-pages-ui-components-tooltip-tsx" */),
  "component---src-pages-ui-components-user-tsx": () => import("./../../../src/pages/ui/components/user.tsx" /* webpackChunkName: "component---src-pages-ui-components-user-tsx" */),
  "component---src-pages-ui-getting-started-mdx": () => import("./../../../src/pages/ui/getting-started.mdx" /* webpackChunkName: "component---src-pages-ui-getting-started-mdx" */),
  "component---src-pages-ui-guides-grid-system-md": () => import("./../../../src/pages/ui/guides/grid-system.md" /* webpackChunkName: "component---src-pages-ui-guides-grid-system-md" */),
  "component---src-pages-ui-guides-install-eva-icons-md": () => import("./../../../src/pages/ui/guides/install-eva-icons.md" /* webpackChunkName: "component---src-pages-ui-guides-install-eva-icons-md" */),
  "component---src-pages-ui-guides-start-from-gatsby-template-md": () => import("./../../../src/pages/ui/guides/start-from-gatsby-template.md" /* webpackChunkName: "component---src-pages-ui-guides-start-from-gatsby-template-md" */),
  "component---src-pages-ui-guides-start-from-nextjs-template-md": () => import("./../../../src/pages/ui/guides/start-from-nextjs-template.md" /* webpackChunkName: "component---src-pages-ui-guides-start-from-nextjs-template-md" */),
  "component---src-pages-ui-themes-corporate-mdx": () => import("./../../../src/pages/ui/themes/corporate.mdx" /* webpackChunkName: "component---src-pages-ui-themes-corporate-mdx" */),
  "component---src-pages-ui-themes-cosmic-mdx": () => import("./../../../src/pages/ui/themes/cosmic.mdx" /* webpackChunkName: "component---src-pages-ui-themes-cosmic-mdx" */),
  "component---src-pages-ui-themes-custom-component-style-mdx": () => import("./../../../src/pages/ui/themes/custom-component-style.mdx" /* webpackChunkName: "component---src-pages-ui-themes-custom-component-style-mdx" */),
  "component---src-pages-ui-themes-dark-mdx": () => import("./../../../src/pages/ui/themes/dark.mdx" /* webpackChunkName: "component---src-pages-ui-themes-dark-mdx" */),
  "component---src-pages-ui-themes-default-mdx": () => import("./../../../src/pages/ui/themes/default.mdx" /* webpackChunkName: "component---src-pages-ui-themes-default-mdx" */),
  "component---src-pages-ui-themes-enable-theme-system-mdx": () => import("./../../../src/pages/ui/themes/enable-theme-system.mdx" /* webpackChunkName: "component---src-pages-ui-themes-enable-theme-system-mdx" */),
  "component---src-pages-ui-themes-theme-system-mdx": () => import("./../../../src/pages/ui/themes/theme-system.mdx" /* webpackChunkName: "component---src-pages-ui-themes-theme-system-mdx" */),
  "component---src-pages-ui-themes-typography-mdx": () => import("./../../../src/pages/ui/themes/typography.mdx" /* webpackChunkName: "component---src-pages-ui-themes-typography-mdx" */)
}

